import request from '../utils/request'

//上传图片
export const overviewPanel = (param) => {
    return request({
        url: '/sys/home/selectHomeStatistics',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};