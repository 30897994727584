<template>
  <div style="display: flex;justify-content: space-between">
    <div class="bottom-div" style="width: 44.5%;height: 400px" >
      <div style="display: flex;justify-content: space-between">
        <div>
          <span style="font-size: 30px;font-weight: bold;">业绩目标</span>
        </div>
        <div style="font-size: 16px" @click="openDialog" class="el-icon-setting"></div>
      </div>
      <div v-if="pieChartLoading === false" class="order-target">
        <div style="width:38%;display:flex;justify-content: center">
          <el-tooltip placement="right">
            <template #content> 目标：{{pieChartData.targetNumberCount}}<br />
              完成率：{{getPercent(pieChartData.accomplishTargetNumberCount,pieChartData.targetNumberCount)}}%<br />
              已完成：{{pieChartData.accomplishTargetNumberCount}}<br />
              可点击右侧部门查看子部门！
            </template>
            <el-progress :stroke-width="12" :width="110" :height="110" type="circle"
                         :percentage="getPercent(pieChartData.accomplishTargetNumberCount,pieChartData.targetNumberCount)">
              <template #default="{ percentage }">
                <div style="font-size: 15px;font-weight: bold" class="percentage-value">{{ percentage }}%</div>
                <span style="font-size: 15px;font-weight: bold" class="percentage-label">已完成</span>
              </template>
            </el-progress>
          </el-tooltip>
        </div>
        <div style="width: 62%;overflow: auto;height: 450px;">
          <div v-for="(item,index) in pieChartData.deptId" :key="item.id" @click="getPieChart(item)" class="course-item">
            <div style="display:flex;justify-content: space-between;margin-bottom: 2px">
              <div style="font-size: 18px;font-weight: bold">
                {{pieChartData.deptName[index]}}
                [ <span style="font-weight: normal">{{pieChartData.targetNumber[index]}} / {{pieChartData.accomplishTargetNumber[index] == null ? 0 : pieChartData.accomplishTargetNumber[index]}}</span> ]
              </div>
              <div></div>
            </div>
            <el-progress :stroke-width="6" :percentage="getPercent(pieChartData.accomplishTargetNumber[index],pieChartData.targetNumber[index]) > 100 ? 100 : getPercent(pieChartData.accomplishTargetNumber[index],pieChartData.targetNumber[index])">
              <template #default>
                <div style="font-size: 15px;margin-left: 2px;font-weight: bold;width: 60px;text-align: right">
                  {{ getPercent(pieChartData.accomplishTargetNumber[index],pieChartData.targetNumber[index]) }}%
                </div>
              </template>
            </el-progress>
          </div>
        </div>
      </div>
      <div v-else class="order-target">
        <div style="width:38%;display:flex;justify-content: center">
          <el-skeleton style="width: 110px;" animated>
            <template #template>
              <el-skeleton-item style="width: 110px;height: 110px" variant="circle"/>
            </template>
          </el-skeleton>
        </div>
        <div style="width: 62%;height: 150px;">
          <div v-for="(item,index) in 6" :key="index">
            <el-skeleton style="width: 400px">
              <template #template>
                <div style="padding: 6px">
                  <div style="display: flex;align-items: center;">
                    <el-skeleton-item variant="text" style="margin-right: 16px;width: 55%" />
                    <el-skeleton-item variant="text" style="width: 20%;margin-left: 10px;" />
                  </div>
                </div>
              </template>
            </el-skeleton>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-div" style="width: 44.5%;height: 400px">
      <div style="display: flex;justify-content: space-between">
        <div>
          <span style="font-size: 30px;font-weight: bold;">课消目标</span>
        </div>
        <div style="font-size: 16px" @click="openDialog" class="el-icon-setting"></div>
      </div>
      <div style="overflow: auto;height: 350px;margin-top: 4px" v-loading="courseLoading" element-loading-text="请稍等...">
        <div v-for="item in courseData" :key="item.id" @click="selectCourseTarget(item.areaName !=null ? null:item.deptId)" class="course-item">
          <div style="display:flex;justify-content: space-between;padding-right: 54px">
            <div style="font-size: 18px;font-weight: bold">
              {{item.areaName !=null ? item.areaName:item.deptName}}
              [ <span style="font-weight: normal">{{item.targetMoney}}/{{item.thisMoney}}</span> ]
            </div>
            <div style="font-size: 18px;"></div>
          </div>
          <el-progress :stroke-width="6" :percentage="item.accomplishRate">
            <template #default>
              <div style="font-size: 15px;font-weight: bold;width: 60px;text-align: right">{{ item.accomplishRate }}%</div>
            </template>
          </el-progress>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {courseTargetStatistics} from "@/api/courseTarget";
import {pieChart} from "@/api/performanceTarget";
export default {
  name: "MonthlyTarget",
  data() {
    return {
      courseQuery:{
        year:null,
        month:null,
        yearAndMonth:null,
        deptIdList:[],
      },
      courseData:[],
      courseLoading:false,

      pieChartQuery:{
        deptId:null,
        month: 7,
        year: 2023
      },
      pieChartData:{},
      pieChartLoading:true,
      pieChartChoiceDate:new Date(),

    }
  },
  methods:{
    getPieChart(id){
      if (this.pieChartChoiceDate != null && this.pieChartChoiceDate != undefined){
        let date = this.getYearAndMonth(this.pieChartChoiceDate);
        this.pieChartQuery.year = date[0];
        this.pieChartQuery.month = date[1];
      }else {
        this.pieChartQuery.year = null;
        this.pieChartQuery.month = null;
      }
      this.pieChartLoading = true;
      this.pieChartQuery.deptId = id;
      pieChart(this.pieChartQuery).then(val => {
        if (val.data != null){
          this.pieChartData = val.data;
          this.pieChartLoading = false;
        }else {
          let that = this;
          setTimeout(function(){
            that.pieChartLoading = false;
          }, 800);
        }
      })
    },
    /**
     * 计算num占total的百分百多少
     * @param num 实际完成
     * @param total 中目标
     * @returns {number}
     */
    getPercent(num, total) {
      if ((num == null || total == null) || (num === 0 || total === 0)){
        return 0;
      }
      return (Math.round(num / total * 10000) / 100.00);// 小数点后两位百分比
    },
    getYearAndMonth(val) {
      let date = new Date(val)
      let month = date.getMonth() + 1;
      month = month < 10 ? ('0' + month) : month;
      return [date.getFullYear(),month]
    },
    selectCourseTarget(deptId){
      this.courseLoading = true;
      if (deptId){
        this.courseQuery.deptIdList = [deptId];
      }else {
        this.courseQuery.deptIdList = [];
      }
      courseTargetStatistics(this.courseQuery).then(val => {
        this.courseLoading = false;
        if(val.code ===200){
          this.courseData = val.data;
        }else {
          this.courseData = [];
          this.$message.error(val.message);
        }
      })
    },
    selectOrderTarget(){

    }
  },
  created() {
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1; // 月份是从0开始计数的，所以需要加1
    this.courseQuery.year = year;
    this.courseQuery.month = month;
    this.courseQuery.yearAndMonth = year + '-' + month;
    this.selectCourseTarget();
    this.getPieChart();
  }
}
</script>

<style scoped>
.bottom-div:hover {
  box-shadow: 0px 1px 20px 1px #e2e2e2;
}
.order-target{
  display: flex;
  margin: 8px 0px 0 0;
  align-items: center;
  justify-content: space-between
}
.bottom-div{
  margin-top: 30px;
  box-shadow: 0px 1px 20px 1px #f5f5f5;
  padding: 22px 24px;
  border-radius: 16px;
}
.course-item{
  padding-left: 20px;
  margin-top: 6px;
}
</style>