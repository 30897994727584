<template>
  <div style="overflow: scroll;">
    <div  style="width: 100%;display:flex;">
      <div style="width: 75%;margin: 25px 0 25px 25px;">
        <!-- 数据总览 -->
        <div class="top-div">
          <overview-panel/>
        </div>
        <!-- 月度目标 -->
        <monthly-target/>
        <!-- 饼状图、柱状图 -->
        <div class="bottom-div" style="display: none">
          <div style="display:flex;height: 100%;justify-content: space-between;">
              <linePie style="width:45%; height:1px"/>
              <line-columnar style="width:45%; height:1px;"/>
          </div>
        </div>
      </div>
      <div class="right-div">
        <div style="font-size: 15px;font-weight: bold;margin-bottom: 26px">快捷入口</div>
        <!-- 首页暂时隐掉 -->
        <div class="itxst" style="display: none">
          <div class="group">
            <draggable v-model="modules.arr1" class="list-group" tag="div" :options="{group:'people'}">
              <li v-for="(element) in modules.arr1" :key="element.name" class="list-group-item">
                {{ element.name }}
              </li>
            </draggable>
          </div>
          <div class="group">
            <draggable v-model="modules.arr2" class="list-group" tag="div" :options="{group:'people'}">
              <li v-for="(element) in modules.arr2" :key="element.name" class="list-group-item">
                {{ element.name }}
              </li>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <area-grouping ref="groupDialog"/>

  </div>

</template>

<script>
import linePie from '../statistical/component/aaa.vue'
import lineColumnar from '../statistical/component/LineColumnar.vue'
import overviewPanel from "./components/OverviewPanel";
import areaGrouping from "./components/AreaGrouping";
import MonthlyTarget from "./components/MonthlyTarget";
import {pieChart} from "@/api/performanceTarget";

import { VueDraggableNext } from 'vue-draggable-next'
export default {
  name: "HomeDetail",
  components:{
    linePie,
    lineColumnar,
    overviewPanel,
    MonthlyTarget,
    draggable:VueDraggableNext,
    areaGrouping,
  },
  data() {
    return {
      setGroupingDialog:false,
      //需要拖拽的数据
      groupA: {
        name: "itxst",
        put: true, //允许拖入
        pull: () => {
          let res = this.es.length > 3;
          //当A组元素小于等于3个时，不允许再拖出元素了
          this.message = res ? "只能拖出1个" : "不能再拖了";
          return res;
        },
      },
      performance:{
        choiceDate:null,
        pieChartData:{},
        pieChartQuery:{
          deptId:null,
          month: 7,
          year: 2023
        }
      },
      modules: {
        arr1: [
          { name: "A组", id: 1, disabledPark: true },
          { name: "B", id: 2 },
          { name: "Java", id: 3 },
          { name: "C", id: 4 },
        ],
        arr2: [
          { name: "B组", id: 5 },
          { name: "员工", id: 6 },
          { name: "报表", id: 7 },
          { name: "库存", id: 8 },
        ],
      },
        visible: false,
        query: {}
      }
    },
  methods:{

    openDialog(){
      this.$refs.groupDialog.openDialog();
    },
    /**
     * 计算num占total的百分百多少
     * @param num 实际完成
     * @param total 中目标
     * @returns {number}
     */
    getPercent(num, total) {
      if ((num == null || total == null) || (num == 0 || total == 0)){
        return 0;
      }
      return (Math.round(num / total * 10000) / 100.00);// 小数点后两位百分比
    },
    getYearAndMonth(val) {
      let date = new Date(val)
      let month = date.getMonth() + 1;
      month = month < 10 ? ('0' + month) : month;
      return [date.getFullYear(),month]
    },
    getPieChart(id){
      if (this.performance.choiceDate != null && this.performance.choiceDate != undefined){
        let date = this.getYearAndMonth(this.performance.choiceDate);
        this.performance.pieChartQuery.year = date[0];
        this.performance.pieChartQuery.month = date[1];
      }else {
        this.performance.pieChartQuery.year = null;
        this.performance.pieChartQuery.month = null;
      }
      this.performance.pieChartQuery.deptId = id;
      pieChart(this.performance.pieChartQuery).then(val => {
        if (val.data != null){
          this.performance.pieChartData = val.data;
          // this.pieChartLoading = false;
        }else {
          let that = this;
          setTimeout(function(){
            that.pieChartLoading = false;
          }, 800);
        }
      })
    },
    onSortA() { },
    //当插入、移除、改变位置时会触发该事件
    onSortB(e) {
      console.log(e);
      var that = this;
      that.message = JSON.stringify(this.modules.arr2);
      setTimeout(function () {
        that.message = "A组只能往B组，不允许排序";
      }, 2000);
    },
    onMove(e) {
      var that = this;
      //不允许停靠到A组的第一个元素
      if (e.relatedContext.element.disabledPark == true) {
        that.message = "不允许停靠到A组的第一个元素";
        setTimeout(function () {
          that.message = "A组只能往B组，不允许排序";
        }, 2000);
        return false;
      }

      return true;
    }
  },
  created(){
    this.getPieChart();

  }
}
</script>

<style scoped>
.top-div{
  font-size: 13px;
  font-weight: bold;
  box-shadow: 0px 1px 10px 1px #f5f5f5;
  padding: 18px 18px 26px 26px;
  border-radius: 16px;
}
.top-div:hover {
  box-shadow: 0px 1px 20px 1px #e2e2e2;
}

.right-div{
  margin: 25px 10px 30px 30px ;
  padding: 24px 30px;
  border-radius: 16px;
  width: 17%;
  box-shadow: 0px 1px 10px 1px #f5f5f5;
}

.right-div:hover {
  box-shadow: 0px 1px 20px 1px #e2e2e2;
}
.bottom-div{
  margin-top: 30px;
  box-shadow: 0px 1px 20px 1px #f5f5f5;
  padding: 24px 30px;
  border-radius: 16px;
}
.bottom-div:hover {
  box-shadow: 0px 1px 20px 1px #e2e2e2;
}

.msg {
  padding: 10px 20px 0px 20px;
}

.itxst {
  background-color: #f1f1f1;
  display: flex;
  padding: 20px;
}

.group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  margin-right: 20px;
}

.item {
  border: solid 1px #ddd;
  padding: 0px;
  text-align: left;
  background-color: #fff;
  margin-bottom: 10px;
  display: flex;
  height: 36px;
  user-select: none;
}

.item>label {
  padding: 6px 10px;
  color: #333;
}

.item>label:hover {
  cursor: move;
}

.item>span {
  padding: 6px 10px;
  color: #666;
}

.ghost {
  border: solid 1px rgb(19, 41, 239) !important;
}

.chosenClass {
  opacity: 1;
  border: solid 1px red;
}

.fallbackClass {
  background-color: aquamarine;
}

</style>