<template>
  <div >
    <div style="display: flex;justify-content: space-between" >
      <div style="margin-bottom: 16px">
        <span style="font-size: 30px;font-weight: bold;">今日数据总览</span>
      </div>
      <el-popover
          :visible="visible"
          placement="left"
          :width="400"
      >
        <div style="padding: 10px 0 10px 0">
          <div><input v-model="query.expireStudentNumber" style="border: none;border-bottom: #1da1f2 1px solid;width: 26px;text-align: center"/>天内到期学员</div>
          <div style="margin: 20px 0">
            <el-date-picker
                :clearable="false"
                style="width: 240px;margin-right: 10px"
                size="small"
                v-model="temporalInterval"
                type="daterange"
                range-separator=" 至 "
                start-placeholder="开始时间"
                end-placeholder="结束时间"
            >
            </el-date-picker>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="设置这个时间区间来查看不同区间的数据总览！"
                placement="top">
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
          <select-area size="small" @areaListId="getAreaListId" :clear="clear"  />
          <el-tooltip
              class="box-item"
              effect="dark"
              content="选择您想查询的区域来调整数据总览展示的数据！"
              placement="top">
            <span class="el-icon-question"></span>
          </el-tooltip>
        </div>
        <div style="text-align: right; margin-top: 20px">
          <el-button size="mini" text @click="visible = false">取消</el-button>
          <el-button size="mini" type="primary" @click="visible = false,getData()">确定</el-button>
        </div>
        <template #reference>
          <div style="font-size: 16px" @click="visible= !visible" class="el-icon-setting"></div>
        </template>
      </el-popover>
    </div>
    <el-skeleton :loading="loading" animated>
          <template #template>
            <div style="display: flex;justify-content: space-around;height: 32px">
              <div v-for="(index) in 7" :key="index">
                <div>
                  <el-skeleton-item variant="text" style="width: 30px" />
                  <el-skeleton-item variant="h3" style="margin-right: 50px" />
                </div>
              </div>
            </div>
          </template>
          <template #default>
            <div style="display: flex;justify-content: space-around;height: 32px">
              <div v-for="(item,index) in overviewPanelData" :key="index">
                <div v-if="item.ordersCount!=null">
                  <div>
                    {{item.value == null ? 0 : item.value}} /
                    {{item.ordersCount.value == null ? 0 : item.ordersCount.value}} /
                    {{item.sendOrderCount.value == null ? 0 : item.sendOrderCount.value}}
                  </div>
                  <div>{{ item.name }}</div>
                </div>
                <div v-else>
                  <div style="display: flex;margin-bottom: 2px">
                    <el-tooltip
                        effect="dark"
                        placement="top">
                      <template #content><div v-html="item.hint"></div></template>
                      <div style="font-size: 18px">{{item.value == null ? 0 : item.value}}</div>
                    </el-tooltip>
                    <el-tooltip
                        effect="dark"
                        placement="top">
                      <template #content><div v-html="item.percentageHint"></div></template>
                      <div style="font-size: 12px;margin-left: 6px" v-html="item.percentage"></div>
                    </el-tooltip>
                  </div>
                    <div>{{ item.name }}</div>
                </div>

            </div>
            </div>
          </template>
        </el-skeleton>
  </div>
</template>

<script>
import selectArea from "../../../components/SelectArea";
import {overviewPanel} from "@/api/homeDetail";

export default {
  name: "OverviewPanel",
  components: {
    selectArea
  },
  data() {
    return {
      visible:false,
      overviewPanelData:[],
      temporalInterval:[new Date(),new Date()],
      loading:false,
      query:{
        startTime:null,
        endTime:null,
        campusIdList:[],
        expireStudentNumber:1
      }
    }
  },
  methods: {
    getAreaListId(val) {
      this.query.campusIdList = val;
    },
    getData(){
      this.loading = true;
      this.query.startTime = this.dateFormat(this.temporalInterval[0]);
      this.query.endTime = this.dateFormat(this.temporalInterval[1]);
      overviewPanel(this.query).then(val=>{
        if (val.code == 200) {
          this.loading = false;
          this.overviewPanelData=val.data;
        } else {
          this.$message.error("获取饼状图统计数据失败");
        }
      })
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
  },

  created() {
    this.getData();
  },
}
</script>

<style scoped>

</style>