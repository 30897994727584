<template>
  <el-dialog v-model="groupingDialog" title="分组统计" width="1400px">
    <div style="display:flex;justify-content: space-between;padding: 0 20px">
      <div class="left-item">
        <el-select size="mini" placeholder="学员类型" style="margin-bottom: 20px" @change="changeStudentType" v-model="studentType">
          <div v-if="sysStudentType==3">
            <el-option label="全部" ></el-option>
            <el-option label="中招体育" value="1"></el-option>
            <el-option label="少年" value="2"></el-option>
            <el-option label="高考体育" value="4"></el-option>
          </div>
          <div v-if="sysStudentType!=3">
            <el-option v-if="sysStudentType==1" label="中招体育" value="1"></el-option>
            <el-option v-else label="少年" value="2"></el-option>
            <el-option label="高考体育" value="4"></el-option>
          </div>
        </el-select>
        <el-tree
            :data="treeList"
            draggable
            :allow-drop="returnFalse"
            @node-drag-start="handleDragstart"
            :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"
        ></el-tree>
      </div>
      <div class="right-item" style="display: flex;justify-content: space-around;flex-wrap: wrap">
        <div v-for="(item,index) in groupData" :key="index">
          <div class="group-item" >
            <el-input v-model="input" size="mini" placeholder="请输入组名" style="margin-bottom: 20px" />
            <div style="height: 260px;overflow: auto;height: 260px" v-on:mouseover="handleMouseover(index)">
                <el-tree
                    v-if="item.length>0"
                    :data="item"
                    draggable
                    :allow-drop="returnTrue"
                    :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"
                >
                  <template #default="{ node,data }">
                    <div class="tree-item">
                      <div>{{ node.label }}</div>
                      <div class="el-icon-delete" @click="remove(node, data)" style="margin: auto 0;font-size: 13px"> </div>
                    </div>
                  </template>
                </el-tree>
              <div v-else style="text-align: center;margin-top: 40%;font-size: 13px">将左侧的树结构拖拽到此处！</div>
            </div>
          </div>
        </div>
        <div class="group-item"
             @click="addGroupItem"
             style="line-height: 300px;text-align: center;font-size: 30px;">+
        </div>
        <div class="placeholder"></div>
        <div class="placeholder"></div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="groupingDialog = false">取消</el-button>
        <el-button type="primary" @click="confirm()">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {listAreaTreeAddCoach} from "@/api/area.js";
export default {
  name: "AreaGrouping",
  props:{
    size: String,
    clear:String
  },
  data() {
    return {
      groupingDialog:false,
      treeList:[],
      treeLists:[],
      areaId:[],

      dragData:{},
      dragState:false,

      groupData:[],

      sysStudentType:localStorage.getItem('studentType'),
      studentType:null,

    }
  },
  methods: {
    confirm(){
      console.log(this.groupData)
    },
    remove(node,data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
    },
    openDialog(){
      this.groupingDialog = true;
    },
    /**
     * 拖拽结束后会触发，鼠标移入事件，然后进行数据移入
     */
    handleMouseover(val) {
      if (this.dragState == true){
        this.groupData[val].push(JSON.parse(JSON.stringify(this.dragData)));
        this.dragState = false;
      }
    },
    /**
     * 开始拖拽事件
     * @param node
     */
    handleDragstart (node) {
      this.dragState = true;
      this.dragData = node.data;
    },
    /**
     * 添加组item
     */
    addGroupItem(){
      this.groupData.push([])
    },

    returnTrue () {
      return true;
    },
    returnFalse () {
      return false;
    },


    /**
     * 树结构的children会存在[]、null 统一处理为 undefined
     * @param commodityType
     * @returns {*}
     */
    getTypeList(commodityType) {
      if (commodityType!=null){
        commodityType.forEach(items => {
          if (items!=null){
            if (items.children!=null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },

    /**
     * 获取树结构的校区数据
     * @param typeId
     */
    getAreaList(typeId){
      listAreaTreeAddCoach(typeId).then(val => {
        if (val.code==200){
          this.treeList = this.getTypeList(val.data);
        }
      })
    }
  },
  created() {
    this.getAreaList();
  },
}
</script>

<style scoped>
.left-item{
  width: 300px;
  padding: 0 20px 20px 20px;
  height: 500px;
  overflow: auto;
  border-radius: 20px;
  box-shadow: 0px 1px 20px 1px #e2e2e2;
}
.right-item{
  width: 100%;
  height: 500px;
  margin-left: 30px;
  padding: 10px 0;
  overflow: auto;
}
.group-item{
  width: 250px;
  padding: 20px;
  height: 300px;
  box-shadow: 0px 1px 10px 1px #f5f5f5;
  border-radius: 20px;
  margin-bottom: 30px;
}
.placeholder{
  width: 240px;
  padding: 20px;
  margin-bottom: 30px;
}
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}
.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
.tree-item{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px
}
</style>